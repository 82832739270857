<template>
    <b-row>
        <b-col md="8" sm="12">
            <b-card>
                <b-form-group label="Metode Pembayaran">
                    <v-select :reduce="option => option.value" :options="[{value: 1, text: 'Tunai'}, {value: 2, text: 'Transfer'}, {value: 3, text: 'Giro'}, {value: 4, text: 'Nota Putih'}]" v-model="payment.metode" label="text"></v-select>
                </b-form-group>
                <b-form-group :label="payment.metode == 3 ? 'Nominal Giro' : 'Nominal Bayar'">
                    <b-form-input v-if="isNotaPutih || isSaldoBigger" disabled autofocus type="text" :value="payment.nominal"></b-form-input>
                    <b-form-input v-else autofocus @keyup="doFormatRupiahNominal" type="text" v-model="payment.nominal"></b-form-input>
                </b-form-group>
                <b-form-group label="Tanggal Transaksi">
                    <b-form-input type="date" v-model="payment.tgl_pembayaran"></b-form-input>
                </b-form-group>
                <b-form-group v-if="payment.metode == 2" label="Nama Bank Tujuan">
                    <b-form-input v-model="payment.bank"></b-form-input>
                </b-form-group>
                <b-form-group v-if="payment.metode == 3" label="No. Giro">
                    <b-form-input type="number" v-model="payment.no_giro"></b-form-input>
                </b-form-group>
                <b-form-group v-if="payment.metode == 3" label="Tanggal Giro">
                    <b-form-input type="date" v-model="payment.tgl_giro"></b-form-input>
                </b-form-group>
                <b-form-group v-if="[3, 4].includes(payment.metode)" label="Tanggal Bayar">
                    <b-form-input type="date" v-model="payment.tgl_bayar"></b-form-input>
                </b-form-group>
                <b-form-group v-if="[2, 3].includes(payment.metode)" label="No. Rekening">
                    <b-form-input type="number" v-model="payment.norek"></b-form-input>
                </b-form-group>
                <b-form-group v-if="[2, 3].includes(payment.metode)" label="Pemilik">
                    <b-form-input type="text" v-model="payment.pemilik"></b-form-input>
                </b-form-group>
                <b-form-group label="Keterangan">
                    <b-form-input type="text" v-model="payment.keterangan"></b-form-input>
                </b-form-group>
                <!-- <b-form-group v-if="$route.meta.diskonable" label="Diskon (opsional)">
                    <b-form-input type="text" @keyup="doFormatRupiahDiskon" v-model="payment.diskon"></b-form-input>
                </b-form-group> -->
            </b-card>
            <!-- List history pembayaran (if action edit / bayar) -->
            <b-card v-if="$route.params.id && !$route.meta.bayar"> 
                <h4 class="mb-2"><strong>Riwayat Pembayaran</strong></h4>
                <section v-if="historyPembayaran.length > 0">
                    <ul class="bg-light p-1 list-unstyled w-100 mb-1" v-for="(payment, i) in historyPembayaran" :key="i">
                        <li class="d-flex justify-content-end mb-2" v-if="$route.meta.edit">
                            <feather-icon class="cursor-pointer text-danger" @click.prevent="removePembayaran({index: i, item: payment})" size="24" icon="XIcon" />
                        </li>
                        <li class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            Metode Pembayaran
                            </div>
                            <div class="detail-amt">
                            <strong>{{ getMetode(payment.metode) }}</strong>
                            </div>
                        </li>
                        <li class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            Nominal Bayar
                            </div>
                            <div class="detail-amt">
                            <strong>{{ getNominal(payment) }}</strong>
                            </div>
                        </li>
                        <li class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            Jumlah Bayar
                            </div>
                            <div class="detail-amt">
                            <strong>{{ transaction.total_bayar }}</strong>
                            </div>
                        </li>
                    </ul>
                </section>
            </b-card>
            <!-- / -->
        </b-col>
        <b-col md="4" sm="12">
            <b-card title="Rincian">
                <div class="d-flex justify-content-between align-items-center">
                    <ul class="list-unstyled w-100">
                        <li class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            Metode
                            </div>
                            <div class="detail-amt">
                            <strong>{{ getMetode(payment.metode) }}</strong>
                            </div>
                        </li>
                        <li v-if="payment.metode == 2" class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            Bank
                            </div>
                            <div class="detail-amt">
                            <strong>{{ payment.bank }}</strong>
                            </div>
                        </li>
                        <li v-if="payment.metode == 3" class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            No. Giro
                            </div>
                            <div class="detail-amt">
                            <strong>{{ payment.no_giro }}</strong>
                            </div>
                        </li>
                        <li v-if="payment.metode == 3" class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            Tanggal Giro
                            </div>
                            <div class="detail-amt">
                            <strong>{{ payment.tgl_giro }}</strong>
                            </div>
                        </li>
                        <li v-if="payment.metode == 3" class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            Tanggal Bayar
                            </div>
                            <div class="detail-amt">
                            <strong>{{ payment.tgl_bayar }}</strong>
                            </div>
                        </li>
                        <li v-if="payment.metode == 3" class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            No. Rekening
                            </div>
                            <div class="detail-amt">
                            <strong>{{ payment.norek }}</strong>
                            </div>
                        </li>
                        <li v-if="payment.metode == 3" class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            Pemilik
                            </div>
                            <div class="detail-amt">
                            <strong>{{ payment.pemilik }}</strong>
                            </div>
                        </li>
                        <li class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            Sub Total
                            </div>
                            <div class="detail-amt">
                            <strong>{{ formatRupiah(totalBayar) }}</strong>
                            </div>
                        </li>
                        <li v-if="$route.params.id && $route.meta.bayar" class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            Total Tagihan
                            </div>
                            <div class="detail-amt">
                            <strong class="text-danger">{{ formatRupiah(totalHutang) }}</strong>
                            </div>
                        </li>
                        <li v-if="payment.diskon && payment.diskon > 0" class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            Diskon
                            </div>
                            <div class="detail-amt">
                            <strong class="text-danger">-{{ formatRupiah(payment.diskon) }}</strong>
                            </div>
                        </li>
                        <li v-if="currentKonsumen && isFirstPayment" class="d-flex justify-content-between mb-1 ">
                            <div class="details-title">
                            Saldo Konsumen
                            </div>
                            <div class="detail-amt">
                            <strong class="text-danger">-{{ (currentKonsumen && parseInt(currentKonsumen.saldo) < parseInt(totalBayar)) ? formatRupiah(currentKonsumen.saldo) : formatRupiah(totalBayar) }}</strong>
                            </div>
                        </li>
                        <li class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            Total
                            </div>
                            <div class="d-flex align-items-center">
                                <h2>
                                    <strong>{{ grandTotal < 0 ? 0 : formatRupiah(grandTotal) }}</strong>
                                </h2>
                                <strong class="text-success" v-if="grandTotal < 0">/lunas</strong>
                            </div>
                        </li>
                        <li class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            Pembayaran
                            </div>
                            <div class="detail-amt">
                            <strong>{{ formatRupiah(payment.nominal) }}</strong>
                            </div>
                        </li>
                        <!-- <li v-if="hasSisa" class="d-flex justify-content-between mb-1">
                            <div class="details-title">
                            Nominal Bayar
                            </div>
                            <div class="detail-amt">
                            <strong>{{ formatRupiah(payment.nominal) }}</strong>
                            </div>
                        </li> -->

                        <li class="mt-3">
                            <b-button :disabled="payment.nominal < 0 || isPaymentOver" block variant="warning" @click.prevent="submit">Simpan</b-button>
                        </li>
                        
                    </ul>
                </div>
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import {BRow, BCol, BCard, BFormGroup, BFormInput,BButton} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
    props: ['payload'],
    computed: {
        isPaymentOver() {
            const nominal = this.unFormatRupiah(this.payment.nominal)
            return nominal > this.grandTotal
        },
        isFirstPayment() {
            return !this.$route.meta.bayar
        },
        isSaldoBigger() {
            return this.isFirstPayment &&this.currentKonsumen && parseInt(this.currentKonsumen.saldo) >= parseInt(this.totalBayar)
        },
        isTransfer() {
            return this.payment.metode == 2
        },
        isGiro() {
            return this.payment.metode == 3
        },
        isNotaPutih() {
            return this.payment.metode == 4
        },
        charge() {
            const trimNominal = this.unFormatRupiah(this.payment.nominal)
            return trimNominal - this.grandTotal
        },
        thereIsCharge() {
            return this.charge > 0 ? true : false
        },  
        subTotal() {
            const saldo = this.currentKonsumen && parseInt(this.currentKonsumen.saldo) > 0 ? this.currentKonsumen.saldo : 0
            const trimDiskon = this.unFormatRupiah(this.payment.diskon)
            return this.totalBayar - (trimDiskon + saldo)
        },
        grandTotal() {
            let saldo = 0
            if(this.currentKonsumen && this.isFirstPayment) {
                saldo = parseInt(this.currentKonsumen.saldo) < parseInt(this.totalBayar) ? this.currentKonsumen.saldo : this.totalBayar
            }
            const trimDiskon = this.unFormatRupiah(this.payment.diskon)
            const isBayarAction = this.$route.params.id && this.totalHutang > 0 && this.$route.meta.bayar
            if(isBayarAction) {
                // total tagihan - (diskon + saldo konsumen)
                return parseInt(this.totalHutang)
            }

            return parseInt(this.totalBayar) - (parseInt(trimDiskon) + parseInt(saldo))
        },
        currentKonsumen() {
            return this.$store.state.penjualan.currentKonsumen
        },
        totalBayar() {
            return this.$store.state.penjualan.currentTotalBayar
        },
        totalHutang() {
            return this.$store.state.penjualan.currentTotalHutang
        },
        isOvercharge() {
            let trimNominal = this.unFormatRupiah(this.payment.nominal)
            if(this.$route.params.id && this.totalHutang > 0) {
                return this.transaction && this.grandTotal < trimNominal
            }

            return this.grandTotal < trimNominal
        },
        debt() {
            const totalSudahBayar = this.transaction.total_pembayaran
            const totalTagihan = this.transaction.total_bayar
            if(totalSudahBayar < totalTagihan) {
                return this.formatRupiah(totalTagihan - totalSudahBayar)
            }

            return false
        }
    },
    components: {
        BRow, BCol, BCard, BFormGroup, BButton, BFormInput, vSelect
    },
    methods: {
        doFormatRupiahNominal(number) {
            this.payment.nominal = this.formatRupiah(this.payment.nominal)
        },
        doFormatRupiahDiskon(number) {
            this.payment.diskon = this.formatRupiah(this.payment.diskon)
        },
        removePembayaran({index, item}) {
            this.$swal({
                title: 'Anda yakin?',
                text: "Setelah dibatalkan, semua history pembayaran akan hilang",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                cancelButton: 'btn btn-danger ml-1',
                },
                buttonsStyling: false,
            })
            .then(async res => {
                if(res.value) {
                    await this.$store.dispatch('penjualan/bayar', [{
                        id: item.id,
                        fungsi: 2
                    }])
                    this.historyPembayaran.splice(index, 1)
                }
            })
        },
        getNominal(item) {
            if(item.metode == 1) {
                return item.tunai
            }
            else if(item.metode == 2) {
                return item.transfer
            }

            return item.giro
        },
        getPembayaran(payment) {
            // if(payment.)
        },
        getMetode(code) {
            if(code == 1) {
                return 'Tunai'
            }
            else if(code == 2) {
                return 'Transfer'
            }

            return 'Giro'
        },
        isThereChange({ nominal, total }) {
            return nominal > total ? true : false
        },
        submit() {
            if(this.isPaymentOver) {
                this.displayError({
                    message: 'Pembayaran tidak bisa melebihi total harga!'
                })
                return false
            }
            if(this.$route.params.id) {
                this.transaction.id = this.$route.params.id
            }
            this.transaction.bank = "-"
            this.transaction.keterangan = this.payment.keterangan
            this.transaction.no_giro = null
            this.transaction.tgl_giro = null
            this.transaction.tgl_bayar = null
            this.transaction.norek = null
            this.transaction.pemilik = null
            this.transaction.nominal = this.unFormatRupiah(this.payment.nominal)
            this.transaction.uang_masuk = this.unFormatRupiah(this.payment.nominal)
            
            if(this.isTransfer) {
                this.transaction.bank = this.payment.bank
                this.transaction.norek = this.payment.norek
                this.transaction.pemilik = this.payment.pemilik
            }
            
            if(this.isGiro){ // giro
                this.transaction.no_giro = this.payment.no_giro
                this.transaction.tgl_giro = this.payment.tgl_giro
                this.transaction.tgl_bayar = this.payment.tgl_bayar
                this.transaction.norek = this.payment.norek
                this.transaction.pemilik = this.payment.pemilik
            }
            const saldoKonsumen = this.currentKonsumen ? this.currentKonsumen.saldo : 0
            this.transaction.metode = this.payment.metode
            this.transaction.tgl_pembayaran = this.payment.tgl_pembayaran
            this.transaction.total_bayar = this.totalBayar
            this.transaction.diskon = 0
            // if(this.$route.meta.diskonable) {
            //     const trimDiskon = this.unFormatRupiah(this.payment.diskon)
            //     this.transaction.diskon = trimDiskon
            // }
            this.transaction.saldo = this.currentKonsumen ? this.currentKonsumen.saldo : 0
            this.transaction.saldo = this.transaction.saldo > this.totalBayar ? this.totalBayar : this.transaction.saldo

            this.$emit('submit', this.transaction)
        },
        getHistoryPembayaran() {
            // if action edit, get history pembayaran
            if(this.$route.params.id) {
                const penjualan = this.$store.getters['penjualan/search'](this.$route.params.id)
                if(!penjualan) {
                    this.$router.push('/penjualan')
                }
    
                this.historyPembayaran = penjualan.pembayaran
            }
        }
    },
    data: () => ({
        historyPembayaran: [],
        payment: {
            metode: 1,
            nominal: 0,
            bank: "-",
            giro: 0,
            no_giro: null,
            tgl_giro: null,
            tgl_bayar: null,
            tgl_pembayaran: null,
            norek: null,
            pemilik: null,
            keterangan: null,
            diskon: 0
        },
        transaction: {
            total_bayar: 0
        }
    }),
    watch: {
        async payload(val) {
            if(val) {
                this.transaction = val
                this.getHistoryPembayaran()
                if(this.$route.params.id && this.totalHutang > 0) {
                    this.payment.nominal = this.totalHutang
                }
            }
        }
    },
    created() {
        this.payment.tgl_pembayaran = this.getCurrentDate()
        this.payment.tgl_bayar = this.getCurrentDate()

        if(this.$route.params.id && this.totalHutang > 0) {
            this.payment.nominal = this.formatRupiah(this.totalHutang)
        }
        // else {
        //     this.payment.nominal = this.$store.state.penjualan.currentTotalBayar
        // }
    },
    async mounted() {
        this.transaction = this.payload
    }
}
</script>
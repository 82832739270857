<template>
    <main>
        <b-row>
            <!--  -->
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col sm="12" md="4">
                            <b-form-group label="Pilih Konsumen">
                                <v-select :options="konsumen" v-model="info.konsumen" label="text">
                                    <template #no-options="{search}">
                                        <i>Konsumen tidak ditemukan</i>
                                        <b-button @click.prevent="addKonsumen(search)" size="sm" variant="primary">
                                            <i>Tambah</i>
                                        </b-button>
                                    </template>
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="4">
                            <b-form-group label="Tanggal Penjualan">
                                <b-form-input type="date" v-model="info.tanggal"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="4">
                            <b-form-group label="Daftar Barang">
                                <b-button :disabled="!info.konsumen" @click.prevent="searchBarang" variant="warning" size="sm">Cari Barang</b-button>
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="4">
                            <b-form-group label="Rute Penjualan">
                                <v-select :options="ruteOptions" v-model="info.rute" label="text"></v-select>
                            </b-form-group>
                        </b-col>
                        <b-col v-if="selectedBarangs.length > 0" cols="12">
                            <slot name="content-selected-barang" :barangs="selectedBarangs" :remove="remove" :doFormatRupiah="doFormatRupiah">
                                
                            </slot>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <!--  -->
            <b-col cols="12" class="d-flex justify-content-center justify-content-md-end">
                <b-card title="Info" style="width: 25rem;">
                    <div class="d-flex justify-content-between align-items-center">
                        <ul class="list-unstyled w-100">
                            <li class="d-flex justify-content-between mb-1">
                                <div class="details-title">
                                Konsumen
                                </div>
                                <div class="detail-amt">
                                <strong>{{ info.konsumen ? info.konsumen.text : '-' }}</strong>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between mb-1">
                                <div class="details-title">
                                Tanggal Penjualan
                                </div>
                                <div class="detail-amt">
                                <strong>{{ info.tanggal }}</strong>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between mb-1">
                                <div class="details-title">
                                Jumlah Barang
                                </div>
                                <div class="detail-amt">
                                <strong>{{ selectedBarangs.length }}</strong>
                                </div>
                            </li>
                            <li class="d-flex justify-content-between mb-3">
                                <div class="details-title">
                                    Subtotal
                                </div>
                                <div class="detail-amt">
                                    <strong>{{ formatRupiah(subTotal) }}</strong>
                                </div>
                            </li>
                            <li>
                                <b-button block variant="warning" @click.prevent="submit">
                                    Metode Pembayaran
                                </b-button>
                            </li>
                        </ul>
                    </div>
                </b-card>
    
                <!-- Modal -->
                <stok-modal @selected-barang="onSelectedBarang" :dataBarang="barangs"></stok-modal>
                <!-- / -->
            </b-col>
        </b-row>
        <b-modal id="konsumen-modal">
            <b-form-group label="Nama Owner">
                <b-form-input autofocus v-model="konsumenPayload.nama_owner"></b-form-input>
            </b-form-group>
            <b-form-group label="Nama Toko">
                <b-form-input v-model="konsumenPayload.nama_toko"></b-form-input>
            </b-form-group>
            <b-form-group label="No. Handphone">
                <b-form-input v-model="konsumenPayload.no_hp"></b-form-input>
            </b-form-group>
            <template #modal-footer>
                <section class="d-flex justify-content-end">
                    <b-button variant="primary" @click.prevent="storeKonsumen">Tambah</b-button>
                </section>
            </template>
        </b-modal>
    </main>
</template>
<script>
import {BRow, BCol, BCard, BFormInput, BButton, BFormGroup,BTable, BModal} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AjuanModal from './ModalAjuan.vue'
import StokModal from './ModalBarang.vue'
import mixin from '../mixin'
export default {
    mixins: [mixin],
    props: ['ajuanList'],
    components: {
        BTable, BRow, BCol, BCard, BFormInput, BButton, BFormGroup, vSelect, StokModal
    },
    computed: {
        thereOverStock() {
            const stockMobil = this.$store.state.gudang.datas
            
            if(stockMobil && stockMobil.length > 0) {
                const isOverStock = this.selectedBarangs.some(barang => {
                    const barangId = barang.barang_id ? barang.barang_id : barang.id
                    const stocks = stockMobil.filter(stock => stock.id_barang === barangId)
                    const sumStock = stocks.reduce((total, item) => item.stok, 0)
                    return parseInt(barang.qty) > sumStock
                })
                return isOverStock
            }

            return true
        },
        ruteOptions() {
            return this.$store.state.penjualan.rutes
        },
        subTotal() {
            if(this.selectedBarangs.length < 1) {
                return 0
            }

            return this.selectedBarangs.reduce((total, item) => {
                let result = total += parseInt(item.qty) * parseInt(item.harga)
                const diskonItem = this.unFormatRupiah(item.diskon)
                if(diskonItem && diskonItem > 0) {
                    result -= diskonItem
                }
                return result
            }, 0)
        }
    },
    data: () => ({
        vehicle: null,
        barangs: [],
        barangStock: [],
        info: {
            konsumen: null,
            tanggal: null,
            rute:null
        },
        selectedBarangs: [],
        fields: [
            {key: 'nama', label: 'Barang'},
            {key: 'qty', label: 'Jumlah'},
            {key: 'satuan', label: 'Satuan'},
            {key: 'harga', label: 'Harga Jual'},
            {key: 'diskon', label: 'Diskon'},
            {key: 'action', label: '#'}
        ],
        konsumenPayload: {
            pelanggan_id: null,
            karyawan_id: null,
            status: 3, // setujui
            nama_toko: '-',
            nama_owner: '',
            no_hp: '08',
            bisnis_fokus: '-',
            tipe_bangunan: '-',
            kepemilikan_bangunan: '-',
            alamat: '-',
            tanggal: '',
            keterangan: '-'
        },
        konsumen: []
    }),
    watch: {
        "$store.state.penjualan.currentRute"(val) {
            if(val) {
                this.info.rute = val
            }
        },
        "info.konsumen"(val) {
            if(val) {
                const {tipe_konsumen} = val
                this.getStockMobil()
                if(this.selectedBarangs.length > 0) {
                    // change harga sesuai dengan konsumen yang diubah
                    this.setHargaSelectedBarang(tipe_konsumen)
                }
            }
            this.$store.commit('penjualan/SET_CURRENT_KONSUMEN', val)
        }
    },
    methods: {
        doFormatRupiah(index) {
            this.selectedBarangs[index].diskon = this.formatRupiah( this.selectedBarangs[index].diskon )
            const slBr = Object.assign([], this.selectedBarangs)
            this.selectedBarangs = []
            this.selectedBarangs = slBr
        },
        // apabila sales change konsumen
        setHargaSelectedBarang(tipe_konsumen) {
            this.selectedBarangs.map(barang => {
                if(barang.list_harga) {
                    const findHarga = barang.list_harga.find(harga => harga.tipe_konsumen_id == tipe_konsumen)
                    if(findHarga) {
                        barang.harga = findHarga.harga
                    }
                }
            })
        },
        async getStockMobil() {
            const gudang_id = this.myGudang.id
            const stokMobil = await this.$store.dispatch('gudang/getDataStokMobil', {
                id_gudang: gudang_id
            })
            const barangsId = stokMobil.map(stok => ({barang_id: stok.id_barang, stok: stok.stok}))

            this.getStockBarang(barangsId)
        },
        addKonsumen(word) {
            this.konsumenPayload.nama_owner = word
            this.$bvModal.show('konsumen-modal')
        },
        async storeKonsumen() {
            this.loading = true
            try {
                const konsumen = await this.$store.dispatch('konsumen/save', [this.konsumenPayload])
                this.loading = false
                await this.getKonsumen()
                const dataKonsumen = this.konsumen.find(k => k.id == konsumen.id)
                this.info.konsumen = {
                    text: `${konsumen.nama_owner} - ${konsumen.nama_toko}`,
                    value: konsumen.id,
                    tipe_konsumen: this.konsumenPayload.pelanggan_id,
                    saldo: dataKonsumen ? dataKonsumen.saldo : 0
                }
                this.$bvModal.hide('konsumen-modal')
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        async removeRincianBarang(index, item) {
            const payload = [
                {
                    id: item.id_rincian,
                    id_penjualan: this.$route.params.id,
                    id_barang: item.id,
                    qty: item.qty,
                    fungsi: 2
                }
            ]
            try {
                await this.$store.dispatch('penjualan/deleteBarang',payload)
                // get current barang
                this.selectedBarangs.splice(index, 1)
                const currentTotalBayar = this.selectedBarangs.length > 0 ? this.selectedBarangs.reduce((total, item) => total += item.harga, 0) : 0
                this.$store.commit('penjualan/SET_CURRENT_TOTAL_BAYAR', currentTotalBayar)
            }
            catch(e) {
                this.displayError(e)
                return false
            }
        },
        remove(index, item) {
            if(item.id_rincian) {
                this.removeRincianBarang(index, item)
            }
            else {
                this.selectedBarangs.splice(index, 1)
                const currentTotalBayar = this.selectedBarangs.length > 0 ? this.selectedBarangs.reduce((total, item) => total += item.harga, 0) : 0
                this.$store.commit('penjualan/SET_CURRENT_TOTAL_BAYAR', currentTotalBayar)
            }
        },
        onSelectedBarang(barangs) {
            if(!this.$route.params.id ) {
                this.selectedBarangs = barangs
            }
            else {
                const br = Object.assign([], this.selectedBarangs)
                this.selectedBarangs = []
                this.selectedBarangs = [...br]
                barangs.map(barang => this.selectedBarangs.push(barang))
            }
        },
        async getKonsumen() {
            const konsumen = await this.$store.dispatch('konsumen/getData', {
                karyawan_id: this.user.karyawan.id,
                status: 3
            })
            konsumen.map(k => {
                const konsumenName = k.type_konsumen ? `${k.nama_toko} (${k.type_konsumen.nama})` : `${k.nama_toko}-${k.nama_owner}`
                this.konsumen.push({
                    text: konsumenName,
                    value: k.id,
                    tipe_konsumen: k.type_konsumen ? k.type_konsumen.id : null,
                    saldo: k.saldo
                })
            })
        },
        async getAjuan() {
            this.ajuan = await this.$store.dispatch('ajuan/getData')
        },
        async searchBarang() {
            this.$bvModal.show('barang-modal')
        },
        async getGudangKendaraan() {
            
            const vehicles = await this.$store.dispatch('gudang/getData', {
                jenis: 2,
                karyawan_id: this.user.karyawan.id
            })
            this.vehicle = vehicles.length > 0 ? vehicles[0] : null
            
        },
        getStockBarang(barangMobil) {
            const barangsId = barangMobil.map(barang => barang.barang_id)
            let params = {
                barang_id: barangsId.join(',')
            }

            this.$store.dispatch('penyimpanan/getData', params)
            .then(stocks => {
                barangMobil.map(barang => {
                    barang.nama = null
                    barang.harga = []
                    barang.list_harga = []
                    barang.konversi = null
                    barang.satuan = null
                    const find = stocks.find(stock => stock.barang && stock.barang.id == barang.barang_id)
                    if(find) {
                        if(find.barang.konversi) {
                            find.barang.konversi.map(kv => {
                                kv.text = kv.satuan ? kv.satuan.satuan : ''
                                kv.value = kv
                            })
                        }
                        barang.list_harga = find.barang.harga_jual
                        barang.nama = find.barang.nama
                        barang.konversi = find.barang.konversi
                        barang.satuan = find.barang.satuan
                        barang.harga = this.setHargaJual(this.info.konsumen.tipe_konsumen, find.barang.harga_jual)
                    }
                })
                this.barangs = barangMobil
            })
            .catch(e => {
                this.displayError(e)
                return false
            })
        },
        getTotalBayar(barangs) {
            return barangs.reduce((total, item) => {
                let result = total += item.qty < 1 ? 0 : (item.qty * item.harga)
                const diskonItem = this.unFormatRupiah(item.diskon)
                if(diskonItem && diskonItem > 0) {
                    result -= diskonItem
                }
                return result
            }, 0)
        },
        submit() {
            
            if(this.selectedBarangs.length < 1) {
                this.displayError({
                    message: 'Harap pilih barang!'
                })
                return false
            }

            if(this.thereOverStock) {
                this.displayError({
                    message: 'Terdapat stok yang berlebih di barang yang dipilih!'
                })
                return false
            }
            
            if(!this.info.rute) {
                this.displayError({
                    message: 'Harap pilih rute penjualan!'
                })
                return false

            }
            const totalBayar = this.getTotalBayar(this.selectedBarangs)
            this.$store.commit('penjualan/SET_CURRENT_TOTAL_BAYAR', totalBayar)

            const payload = {
                id_sales: this.user.karyawan.id,
                id_gudang: this.vehicle.id,
                id_konsumen: this.info.konsumen.value,
                tanggal: this.info.tanggal,
                rute_id: this.info.rute.value,
                total_bayar: totalBayar,
                uang_masuk: 0,
                metode: "",
                bank: "-",
                transfer: 0,
                tunai: 0,
                nominal: 0,
                item: this.selectedBarangs.map(item => ({id_rincian: item.id_rincian ? item.id_rincian : null, id_barang: item.barang_id ? item.barang_id : item.id, qty: item.qty, harga_jual: item.harga, diskon: this.unFormatRupiah(item.diskon)}))
            }
            this.$emit('next-step', payload)
        },
        async getTypeKonsumen() {
            const types = await this.$store.dispatch('tipekonsumen/getData')
            const retail = types.find(type => type.nama.toLowerCase() == 'retail')
            this.konsumenPayload.pelanggan_id = retail.id
            this.konsumenPayload.karyawan_id = this.user.karyawan.id
        }
    },
    async created() {
        this.info.tanggal = this.getCurrentDate()

        this.konsumenPayload.tanggal = this.getCurrentDate()
        await this.getGudangKendaraan()
        if(this.$route.params.id) {
            this.getStockMobil()
        }
    },
    async mounted() {
        if(this.isSales) {
            await this.getTypeKonsumen()
        }
        
        await this.getKonsumen()
        if(this.$route.params.id) {
            // get penjualan
            const penjualan = await this.$store.getters['penjualan/search'](this.$route.params.id)
            if(!penjualan) {
                this.$router.push('/penjualan')
            }
            penjualan.konsumen.value = penjualan.konsumen.id
            penjualan.konsumen.text = penjualan.konsumen.nama_toko
            this.info.konsumen = penjualan.konsumen
            this.info.konsumen.tipe_konsumen = this.info.konsumen.type_konsumen ? this.info.konsumen.type_konsumen.id : null
            this.info.tanggal = penjualan.tanggal

            // get available barang
            const selectedBarangs = []
            penjualan.rincian.map(rincian => {
                if(rincian.barang) {
                    selectedBarangs.push({
                        harga: this.setCurrentHarga(rincian.barang, this.info.konsumen),
                        ...rincian.barang,
                        qty: rincian.qty,
                        id_penjualan: rincian.id_penjualan,
                        id_rincian: rincian.id,
                        diskon: rincian.diskon
                    })
                }
            })
            setTimeout(() => {
                this.selectedBarangs = selectedBarangs
            }, 1000)

        }
    }
}
</script>
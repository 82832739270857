<template>
    <b-modal size="xl" id="barang-modal" :no-close-on-backdrop="true">
        <b-row>
            <b-col cols="12">
                <b-table
                    striped
                    hover
                    small
                    responsive
                    :fields="fields"
                    :items="barangs"
                    :per-page="perPage"
          :current-page="currentPage"
                >
                    <template #head(checkbox)>
                        <b-form-checkbox v-model="allChecked"></b-form-checkbox>
                    </template>
                    <template #cell(checkbox)="{item}">
                        <b-form-checkbox v-if="item.stok > 0" :value="item" v-model="selectedBarangs"></b-form-checkbox>
                        <feather-icon v-else icon="SlashIcon" class="text-damger" />
                    </template>
                    <template #cell(harga)="{item}">
                        {{ formatRupiah(item.harga) }}
                    </template>
                    <template #cell(qty)="{item, index}">
                        <b-form-input v-if="item.stok > 0" type="number" @keyup="onSatuanKonversiSelected(index)" v-model="item.qty" class="w-75"></b-form-input>
                        <i class="text-danger" v-else>Stok kosong</i>
                    </template>
                    <template #cell(stok)="{item}">
                        {{item.stok}} {{item.satuan ? item.satuan.satuan : ''}}
                    </template>
                    <template #cell(satuan)="{item, index}">
                        <b-form-select @change="onSatuanKonversiSelected(index)" v-model="item.selectedKonversi" v-if="item.konversi && item.konversi.length > 0" :options="item.konversi"></b-form-select>
                        <span v-else>
                            {{ item.satuan ? item.satuan.satuan : '-' }}
                        </span>
                    </template>
                    <template #cell(converted)="{item}">
                        <strong v-if="item.konversi && item.konversi.length > 0">
                            {{ item.converted }} <span v-if="item.converted > 0">{{ item.satuan ? item.satuan.satuan.toLowerCase() : '' }}</span>
                        </strong>
                        <i v-else class="text-danger">
                            <small>Satuan Konversi belum diinput</small>
                        </i>
                    </template>
                </b-table>
            </b-col>
            <b-col cols="12">
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
                />
            </b-col>
        </b-row>
        <template #modal-footer>
            <b-button variant="warning" @click.prevent="chooseBarang">Pilih Barang</b-button>
        </template>
    </b-modal>    
</template>
<script>
import {BRow, BCol, BButton, BPagination, BFormGroup, BFormCheckbox, BFormInput, BTable, BFormSelect} from 'bootstrap-vue'
import vSelect from 'vue-select'
import mixin from '../mixin'
export default {
    components: {
        BPagination,
        vSelect, BRow, BCol, BFormGroup, BFormCheckbox, BFormInput, BTable, BButton, BFormSelect
    },
    props: ['dataBarang'],
    data: () => ({
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        allChecked: false,
        ajuan: [],
        barangs: [],
        selectedBarangs: [],
        selectedAjuan: null,
        fields: [
            { key: 'checkbox', label: 'No' },
            { key: 'nama', label: 'Barang' },
            { key: 'harga', label: 'Harga' },
            { key: 'stok', label: 'Stok Mobil' },
            { key: 'qty', label: 'Jumlah' },
            { key: 'satuan', label: 'Satuan' },
            { key: 'converted', label: 'Jumlah Setelah Konversi' }
        ]
    }),
    watch: {
        allChecked(val) {
            const availableBarangs = this.barangs.filter(item => item.stok > 0)
            if(val && availableBarangs.length > 0) {
                this.selectedBarangs = this.barangs
            }
            else {
                this.selectedBarangs = []
            }
        },
        dataBarang(val) {
            if(val) {
                this.barangs = val
                this.totalRows = val.length
            }
        }
    },
    created() {
        if(this.dataBarang) {
            this.totalRows = this.dataBarang.length
        }
    },
    methods: {
        async onSatuanKonversiSelected(index) {
            const barangs = Object.assign([], this.barangs)
            const barang = barangs[index]
            const qtyRequest = barang.qty

            if(barang.selectedKonversi && qtyRequest > 0) {
                const konversi = barang.selectedKonversi
                
                // set payload for konversi
                const payload = {
                    tipe: 2, // konversi ke satuan terkecil, 1 sebaliknya
                    id_barang: konversi.id_barang,
                    id_satuan: konversi.id_satuan,
                    jumlah: qtyRequest
                }
                const hasilKonversi = await this.$store.dispatch('konversi/konversiBarang', payload)
                barangs[index].converted = hasilKonversi

                // update reactivity of array
                this.barangs = barangs
            }
        },
        chooseBarang() {
            this.selectedBarangs.map(barang => {
                barang.old_qty = barang.qty
                barang.qty = barang.converted && barang.converted > 0 ? barang.converted : barang.qty
                barang.diskon = 0
                barang.stok = barang.stok
            })
            this.$emit('selected-barang', this.selectedBarangs)
            this.$bvModal.hide('barang-modal')
        },
    }
}
</script>